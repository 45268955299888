<template>
  <div class="home">
    <IDValidation msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import IDValidation from '@/components/IDValidation.vue'

export default {
  name: 'ValidationView',
  components: {
    IDValidation
  }
}
</script>

<template>
  <div class="container mt-5 py-5">
    <div class="row justify-content-center">
      <div class="card p-4 col-10 col-sm-10 col-sm-7 col-md-6 col-xl-6 col-xxl-4">
        <div class="form-group">
          <label for="cedula">Número de Cédula:</label>
          <input type="text" class="form-control mt-3 mb-3" v-model="cedula" id="cedula" />
        </div>
        <button class="btn btn-primary" @click="consultarCedula">Consultar Cédula</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      cedula: "",
      bearerToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0MDE1OTc2NzFlOThiNjRjYTc1ZjMwYSIsImNsaWVudCI6IkFkbWluIiwiZXhwaXJlcyI6MTgzNTQ5MDAzOCwiaXNBY3RpdmUiOnRydWUsImNyZWF0ZWRBdCI6IjIwMjMtMDMtMDNUMDI6MjA6MzguODY3WiIsInN0YWZmIjp0cnVlLCJpYXQiOjE2Nzc4MTAwMzl9.1_CEV1BFIBuXxbb8QPs0SchWTbSf4v_1uWrTE4nD3UY",
      biometricEndpoint: 'https://api-test.signaltech.com.ec/biometric'
    };
  },
  methods: {
    ...mapMutations(["SET_RC", "SET_BIOMETRIC"]),

    async consultarCedula() {
      try {
        let rc = await axios.get('registroCivil.json')
        rc = rc.data[this.cedula]

        if (rc) {
          this.SET_RC(rc);
          const parts = rc.Nombre.split(' ');
          const name = {
            first: parts[2],
            second: parts[3],
            lastname: parts[0],
            secondLastname: parts[1]
          }

          const headers = {
            Authorization: `Bearer ${this.bearerToken}`,
          };

          const biometric = await axios.post(this.biometricEndpoint, {
            sign_data: {
              personal_data: {
                name: `${name.first} ${name.second}`,
                lastname: `${name.lastname} ${name.secondLastname}`,
                serial_number: rc.NUI,
                gender: rc.Sexo,
                fingerprintCode: "00000000",
                spouse: "",
                marriageDate: "",
                civilStatus: rc.EstadoCivil,
                identificationCondition: "EXTRANJERO",
                identificationDate: "09/01/2023",
                birthdate: "03/02/1993",
                nationality: rc.Nacionalidad,
                placeOfBirth: "MARACAIBO",
                residence: "PICHINCHA/QUITO/COCHAPAMBA",
                street: "ALONSO DE TORRES",
                houseNumber: "305",
                education: "HIGHER EDUCATION",
                profession: "LICENTIATE",
                signature: rc.Firma
              },
              photo_digercic: rc.Fotografia
            }
          }, { headers })

          this.SET_BIOMETRIC(biometric.data);

          this.$router.push({ name: "biometria", params: { cedula: this.cedula } });
        }
      } catch (error) {
        console.error("Error al hacer la petición POST:", error);
      }
    },
  },
};
</script>
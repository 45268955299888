import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rc: null,
    biometric: null
  },
  getters: {
  },
  mutations: {
    SET_RC(state, rcData) {
      state.rc = rcData;
    },
    SET_BIOMETRIC(state, biometricData){
      state.biometric = biometricData
    }
  },
  actions: {
  },
  modules: {
  }
})
